const eventData = [
    {
        "name": "SwingVester",
        "startDate": "2024-12-28T00:00:00.000000Z",
        "endDate": "2025-01-01T00:00:00.000000Z",
        "location": {"city": "Wels", "state": "Upper Austria", "country": "Austria"},
        "url": "https://www.swingvester.com/"
    },
    {
        "name": "Countdown Swing",
        "startDate": "2025-01-01T00:00:00.000000Z",
        "endDate": "2025-01-06T00:00:00.000000Z",
        "location": {"city": "Framingham", "state": "Massachusetts", "country": "United States"},
        "url": "https://countdownswingboston.com/"
    },
    {
        "name": "Budafest",
        "startDate": "2025-01-08T00:00:00.000000Z",
        "endDate": "2025-01-13T00:00:00.000000Z",
        "location": {"city": "Budapest", "state": "", "country": "Hungary"},
        "url": "https://wcs-budafest.com/"
    },
    {
        "name": "Monterey SwingFest",
        "startDate": "2025-01-16T00:00:00.000000Z",
        "endDate": "2025-01-20T00:00:00.000000Z",
        "location": {"city": "Monterey", "state": "California", "country": "United States"},
        "url": "https://www.montereyswing.com/"
    },
    {
        "name": "Freedom Swing",
        "startDate": "2025-01-24T00:00:00.000000Z",
        "endDate": "2025-01-26T00:00:00.000000Z",
        "location": {"city": "Philadelphia", "state": "Pennsylvania", "country": "United States"},
        "url": "https://freedomswingdance.com/"
    },
    {
        "name": "Choreo Camp",
        "startDate": "2025-01-30T00:00:00.000000Z",
        "endDate": "2025-02-02T00:00:00.000000Z",
        "location": {"city": "Seattle", "state": "Washington", "country": "United States"},
        "url": "https://choreocamp.com/"
    },
    {
        "name": "Capital Swing",
        "startDate": "2025-02-14T00:00:00.000000Z",
        "endDate": "2025-02-17T00:00:00.000000Z",
        "location": {"city": "Sacramento", "state": "California", "country": "United States"},
        "url": "https://www.capitalswingconvention.com/"
    },
    {
        "name": "Rose City Swing",
        "startDate": "2025-02-21T00:00:00.000000Z",
        "endDate": "2025-02-23T00:00:00.000000Z",
        "location": {"city": "Portland", "state": "Oregon", "country": "United States"},
        "url": "https://rosecityswing.com/"
    },
    {
        "name": "Dallas Workshop Weekend",
        "startDate": "2025-02-28T00:00:00.000000Z",
        "endDate": "2025-03-02T00:00:00.000000Z",
        "location": {"city": "Dallas", "state": "Texas", "country": "United States"},
        "url": ""
    },
    {
        "name": "Woodlands Workshop Weekend",
        "startDate": "2025-03-07T00:00:00.000000Z",
        "endDate": "2025-03-09T00:00:00.000000Z",
        "location": {"city": "The Woodlands", "state": "Texas", "country": "United States"},
        "url": ""
    },
    {
        "name": "Chicago Classic",
        "startDate": "2025-03-14T00:00:00.000000Z",
        "endDate": "2025-03-16T00:00:00.000000Z",
        "location": {"city": "Chicago", "state": "Illinois", "country": "United States"},
        "url": "https://thechicagoclassic.com/"
    },
    {
        "name": "Novice Invitational",
        "startDate": "2025-03-21T00:00:00.000000Z",
        "endDate": "2025-03-23T00:00:00.000000Z",
        "location": {"city": "Houston", "state": "Texas", "country": "United States"},
        "url": "https://novice-invitational.com/"
    },
    {
        "name": "UK West Coast Swing Championships",
        "startDate": "2025-03-27T00:00:00.000000Z",
        "endDate": "2025-03-31T00:00:00.000000Z",
        "location": {"city": "London", "state": "", "country": "United Kingdom"},
        "url": "https://www.ukwcschamps.com/"
    },
    {
        "name": "City of Angels",
        "startDate": "2025-04-10T00:00:00.000000Z",
        "endDate": "2025-04-13T00:00:00.000000Z",
        "location": {"city": "Los Angeles", "state": "California", "country": "United States"},
        "url": "https://cityofangelswcs.com/"
    },
    {
        "name": "Easter Swing",
        "startDate": "2025-04-17T00:00:00.000000Z",
        "endDate": "2025-04-20T00:00:00.000000Z",
        "location": {"city": "Seattle", "state": "Washington", "country": "United States"},
        "url": "https://www.easterswing.org/"
    },
    {
        "name": "Minnesota Workshop Weekend",
        "startDate": "2025-05-02T00:00:00.000000Z",
        "endDate": "2025-05-04T00:00:00.000000Z",
        "location": {"city": "Minneapolis", "state": "Minnesota", "country": "United States"},
        "url": ""
    },
    {
        "name": "Swingsation",
        "startDate": "2025-05-09T00:00:00.000000Z",
        "endDate": "2025-05-11T00:00:00.000000Z",
        "location": {"city": "Gold Coast", "state": "QLD", "country": "Australia"},
        "url": "https://rawconnection.com.au/swingsation/"
    },
    {
        "name": "GNDC",
        "startDate": "2025-05-23T00:00:00.000000Z",
        "endDate": "2025-05-26T00:00:00.000000Z",
        "location": {"city": "Atlanta", "state": "Georgia", "country": "United States"},
        "url": "https://www.usagrandnationals.com/"
    },
    {
        "name": "French Open",
        "startDate": "2025-05-28T00:00:00.000000Z",
        "endDate": "2025-06-01T00:00:00.000000Z",
        "location": {"city": "Paris", "state": "", "country": "France"},
        "url": "https://www.fowcs.com/"
    },
    {
        "name": "Jack and Jill O'Rama",
        "startDate": "2025-06-05T00:00:00.000000Z",
        "endDate": "2025-06-09T00:00:00.000000Z",
        "location": {"city": "Orange County", "state": "California", "country": "United States"},
        "url": "https://jackandjillorama.com/"
    },
    {
        "name": "California Dance Festival",
        "startDate": "2025-06-20T00:00:00.000000Z",
        "endDate": "2025-06-22T00:00:00.000000Z",
        "location": {"city": "Sacramento", "state": "California", "country": "United States"},
        "url": "https://www.dancecalifornia.com/"
    },
    {
        "name": "Liberty Swing",
        "startDate": "2025-06-27T00:00:00.000000Z",
        "endDate": "2025-06-30T00:00:00.000000Z",
        "location": {"city": "New Brunswick", "state": "New Jersey", "country": "United States"},
        "url": "https://libertyswing.com/"
    },
    {
        "name": "Wild Wild Westie",
        "startDate": "2025-07-04T00:00:00.000000Z",
        "endDate": "2025-07-06T00:00:00.000000Z",
        "location": {"city": "Dallas", "state": "Texas", "country": "United States"},
        "url": "https://www.wildwildwestie.com/"
    },
    {
        "name": "Midwest Westie Fest",
        "startDate": "2025-07-17T00:00:00.000000Z",
        "endDate": "2025-07-20T00:00:00.000000Z",
        "location": {"city": "Kansas City", "state": "Kansas", "country": "United States"},
        "url": "https://www.midwestwestiefest.com/"
    },
    {
        "name": "Mission City Workshop Weekend",
        "startDate": "2025-07-25T00:00:00.000000Z",
        "endDate": "2025-07-28T00:00:00.000000Z",
        "location": {"city": "Boston", "state": "Massachusetts", "country": "United States"},
        "url": ""
    },
    {
        "name": "Swingtacular",
        "startDate": "2025-08-08T00:00:00.000000Z",
        "endDate": "2025-08-10T00:00:00.000000Z",
        "location": {"city": "San Francisco", "state": "California", "country": "United States"},
        "url": "https://www.dancegeekproductions.art/"
    },
    {
        "name": "SwingTime",
        "startDate": "2025-08-15T00:00:00.000000Z",
        "endDate": "2025-08-17T00:00:00.000000Z",
        "location": {"city": "Denver", "state": "Colorado", "country": "United States"},
        "url": "https://swingtimewcs.com/"
    },
    {
        "name": "Summer Hummer",
        "startDate": "2025-08-22T00:00:00.000000Z",
        "endDate": "2025-08-24T00:00:00.000000Z",
        "location": {"city": "Boston", "state": "Massachusetts", "country": "United States"},
        "url": "https://summerhummerboston.com/"
    },
    {
        "name": "Desert City Swing",
        "startDate": "2025-08-29T00:00:00.000000Z",
        "endDate": "2025-09-01T00:00:00.000000Z",
        "location": {"city": "Phoenix", "state": "Arizona", "country": "United States"},
        "url": "https://desertcityswing.com/"
    },
    {
        "name": "Albany Workshop Weekend",
        "startDate": "2025-09-12T00:00:00.000000Z",
        "endDate": "2025-09-14T00:00:00.000000Z",
        "location": {"city": "Albany", "state": "New York", "country": "United States"},
        "url": ""
    },
    {
        "name": "Eindhoven Workshop Weekend",
        "startDate": "2025-09-19T00:00:00.000000Z",
        "endDate": "2025-09-21T00:00:00.000000Z",
        "location": {"city": "Amsterdam", "state": "", "country": "Netherlands"},
        "url": ""
    },
    {
        "name": "Gotham Swing Workshop Weekend",
        "startDate": "2025-10-03T00:00:00.000000Z",
        "endDate": "2025-10-06T00:00:00.000000Z",
        "location": {"city": "New York City", "state": "New York", "country": "United States"},
        "url": ""
    },
    {
        "name": "Milan Modern Swing",
        "startDate": "2025-10-10T00:00:00.000000Z",
        "endDate": "2025-10-12T00:00:00.000000Z",
        "location": {"city": "Milan", "state": "", "country": "Italy"},
        "url": "https://www.westcoastswingmilan.com/"
    },
    {
        "name": "Boogie by the Bay",
        "startDate": "2025-10-17T00:00:00.000000Z",
        "endDate": "2025-10-19T00:00:00.000000Z",
        "location": {"city": "San Francisco", "state": "California", "country": "United States"},
        "url": "https://boogiebythebay.com/"
    },
    {
        "name": "Swing City Chicago",
        "startDate": "2025-10-24T00:00:00.000000Z",
        "endDate": "2025-10-27T00:00:00.000000Z",
        "location": {"city": "Chicago", "state": "Illinois", "country": "United States"},
        "url": "https://swingcitychicago.com/"
    },
    {
        "name": "Tampa Bay Classic",
        "startDate": "2025-11-06T00:00:00.000000Z",
        "endDate": "2025-11-09T00:00:00.000000Z",
        "location": {"city": "Tampa", "state": "Florida", "country": "United States"},
        "url": ""
    },
    {
        "name": "DCSX",
        "startDate": "2025-11-13T00:00:00.000000Z",
        "endDate": "2025-11-16T00:00:00.000000Z",
        "location": {"city": "Washington, D.C.", "state": "", "country": "United States"},
        "url": "https://dcswingexperience.com/"
    },
    {
        "name": "The Open",
        "startDate": "2025-11-14T00:00:00.000000Z",
        "endDate": "2025-11-18T00:00:00.000000Z",
        "location": {"city": "Burbank", "state": "California", "country": "United States"},
        "url": "https://theopenswing.com/"
    },
    {
        "name": "The AFter Party",
        "startDate": "2025-11-14T00:00:00.000000Z",
        "endDate": "2025-11-18T00:00:00.000000Z",
        "location": {"city": "Orange County", "state": "California", "country": "United States"},
        "url": "https://tapwcs.com/"
    },
    {
        "name": "Krakow",
        "startDate": "2025-12-11T00:00:00.000000Z",
        "endDate": "2025-12-16T00:00:00.000000Z",
        "location": {"city": "Krakow", "state": "", "country": "Poland"},
        "url": ""
    },
    {
        "name": "Countdown Swing",
        "startDate": "2025-12-31T00:00:00.000000Z",
        "endDate": "2026-01-04T00:00:00.000000Z",
        "location": {"city": "Boston", "state": "Massachusetts", "country": "United States"},
        "url": ""
    },
];

export default eventData;