import React, { useEffect, useRef } from 'react';
import EventItem from './EventItem';

const EventList = ({ events }) => {
    var prevMonth = null;
    const currentMonthRef = useRef(null);

    useEffect(() => {
        if (currentMonthRef.current) {
            // Scroll to the current month element
            currentMonthRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, []); // Empty dependency array to ensure this effect runs only once after initial render


    return (
        <div className="scroll-container">
            <ul className="event-list">
                {events.map((event, index) => {
                    // divide months
                    const currMonth = new Date(event.startDate).getMonth();
                    const currYear = new Date(event.startDate).getFullYear();
                    var separator = null;

                    if (currMonth !== prevMonth) {
                        separator = 
                            <React.Fragment> 
                                <br />
                                <span 
                                    ref={currMonth === new Date().getMonth() && currYear === new Date().getFullYear() ? currentMonthRef : null}
                                    style={{display: "flex", justifyContent: "center", fontSize: 23}}>
                                        <i> - {getMonthName(currMonth)}, {currYear} - </i>
                                </span> 
                                <br />
                                <br />
                            </React.Fragment>;
                    }

                    prevMonth = currMonth;

                    return (
                        <React.Fragment key={index}>
                            {separator}
                            <li key={index} className="event-list-li">
                                <EventItem e={event}/>
                            </li>
                            <br />
                            <hr style={{marginLeft: "90px", marginRight: "90px"}}/>
                            <br />
                        </React.Fragment>
                    );
                })}
            </ul>
        </div>
    );
};

function getMonthName(monthIndex) {
    const months = [
        'JANUARY', 'FEBRUARY', 'MARCH', 'APRIL',
        'MAY', 'JUNE', 'JULY', 'AUGUST',
        'SEPTEMBER', 'OCTOBER', 'NOVEMBER', 'DECEMBER'
    ];
    return months[monthIndex];
}

export default EventList;
